$(document).ready(() => {
  const $shippingAddonForm = $('[data-selector="shipping-addon-form"]');
  if (!$shippingAddonForm.length) return;

  const $shippingCountriesSelect = $('[data-selector="shipping-countries-select"]');


  const initCountryCustomSelect = () => {
    $shippingCountriesSelect.select2({width: 'resolve'});  // need to override the changed default
  };

  const addCountriesSelectToHTML = () => {
    if (!countries) return;
    if (!Array.isArray(countries)) return;

    countries.forEach((country) => {
      $shippingCountriesSelect.append(`<option value="${country.id}"
                                               data-price="${country.price}"
                                               ${country.id === 1 ? 'selected': ''}
                                        >
                                         ${country.name}
                                       </option>`);
    });
  };

  addCountriesSelectToHTML();
  initCountryCustomSelect();
});
