import {
  SECONDS_PER_DAY,
  SECONDS_PER_HOUR,
  SECONDS_PER_MINUTE
} from '../config';

$(document).ready(() => {
  const $timer = $('[data-selector="main-banner-timer"]');
  if (!$timer.length) return;

  const timerExpiresAttr = 'data-expires';

  //timer panel sections' attributes
  const days = 'main-banner-timer-days';
  const hours = 'main-banner-timer-hours';
  const minutes = 'main-banner-timer-mins';
  const seconds = 'main-banner-timer-secs';


  const formatNumberForDisplay = (string) => {
    return string.toLocaleString('en-US', {
      minimumIntegerDigits: 2
    });
  };

  const secondsToDhms = (seconds) => {
    const fullDaysLeft = Math.floor(seconds / SECONDS_PER_DAY);
    const hoursRemain = seconds % SECONDS_PER_DAY;
    const fullHoursLeft = Math.floor(hoursRemain / SECONDS_PER_HOUR);
    const minsRemain = hoursRemain % SECONDS_PER_HOUR;
    const fullMinsLeft = Math.floor(minsRemain / SECONDS_PER_MINUTE);
    const secondsLeft = minsRemain % SECONDS_PER_MINUTE;

    return {
      fullDaysLeft,
      fullHoursLeft,
      fullMinsLeft,
      secondsLeft
    };
  };

  const setTimerValue = ($timer, secondsInterval) => {
    const {fullDaysLeft, fullHoursLeft, fullMinsLeft, secondsLeft} = secondsToDhms(secondsInterval);
    const timerData = {
        [days]: formatNumberForDisplay(fullDaysLeft),
        [hours]: formatNumberForDisplay(fullHoursLeft),
        [minutes]: formatNumberForDisplay(fullMinsLeft),
        [seconds]: formatNumberForDisplay(secondsLeft)
      };

    for (const [timerSectionAttr, value] of Object.entries(timerData)) {
      $timer.find(`[data-selector=${timerSectionAttr}]`).text(value);
    }
  };

  const setTimer = ($timer, timerInterval) => {
    let secondsLeft = Math.floor(timerInterval / 1000);

    setTimerValue($timer, secondsLeft); // set timer immediately the first time to avoid delay
    const countdown = setInterval(() => {
      if (--secondsLeft <= 0) clearInterval(countdown);

      setTimerValue($timer, secondsLeft);
    }, 1000);
  };

  const dateDiffUTC = (dateFrom, dateUntil) => {
    const dateFromToUTC = new Date(dateFrom.toUTCString());
    const dateUntilToUTC = new Date(dateUntil.toUTCString());
    return dateUntilToUTC - dateFromToUTC;
  };

  const handleTimerInit = (index, timerElement) => {
    const $timer = $(timerElement);
    const timerExpires = $timer.attr(timerExpiresAttr);
    const timerExpiresDate = new Date(timerExpires);

    const timerInterval = timerExpiresDate ? dateDiffUTC(new Date(), timerExpiresDate) : null;

    !timerInterval || timerInterval <= 0
      ? setTimerValue($timer, 0)
      : setTimer($timer, timerInterval);
  };

  $timer.each(handleTimerInit.bind(this));
});
