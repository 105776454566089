import {
  ENDPOINT,
  FEEDBACK_TYPES
} from '../config';
import {
  toggleButtonLoading,
  getBrowser
} from '../utils';

$(function () {
  const $contactUsForm = $('[data-selector="contact-us-form"]');
  const contactUsFormSubmitBtn = '[data-selector="contact-us-form-submit"]';
  const $contactUsFormSubmitBtn = $(contactUsFormSubmitBtn);
  const $contactUsModal = $('#contactUsModal');
  const $contactUsSuccessModal = $('#contactUsSuccess');
  const $contactUsErrorModal = $('#contactUsError');
  const formValidation = $contactUsForm.parsley();

  const clearFields = function () {
    const $inputs = $contactUsForm.find('input');
    const $textareas = $contactUsForm.find('textarea');
    const $selects = $contactUsForm.find('select');

    $selects.val('');
    $textareas.val('');
    $inputs.val('');
  };

const showSuccessModal = function() {
    $contactUsSuccessModal.modal('show');
  };

  const showErrorModal = function() {
    $contactUsErrorModal.modal('show');
  };

  const hideContactUsModal = function() {
    $contactUsModal.modal('hide');
    formValidation.reset();
    clearFields();
  };

  const getContactFormData = function() {
    const formDataArr = $contactUsForm.serializeArray();
    const data = {};

    formDataArr.forEach(function (item) {
      data[item.name] = item.value;
    });

    data.feedback_type = FEEDBACK_TYPES.REQUEST;
    data.device_page = window.location.href;
    data.device_browser = getBrowser();
    data.device_os = navigator.platform;
    data.previous_page = document.referrer;
    data.time = new Date();
    data.screen_resolution = screen.width + 'x' + screen.height;

    return data;
  };

  const submitForm = function() {
    const data = getContactFormData();
    toggleButtonLoading($contactUsFormSubmitBtn, true);

    $.ajax({
      url: ENDPOINT.FEEDBACK,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(data)
    })
      .then(function () {
        toggleButtonLoading($contactUsFormSubmitBtn, false);
        hideContactUsModal();
        showSuccessModal();
      }, function () {
        toggleButtonLoading($contactUsFormSubmitBtn, false);
        hideContactUsModal();
        showErrorModal();
      });
  };

  $(document)
    .on('click', contactUsFormSubmitBtn, function (e) {
      if (!formValidation.isValid()) return;
      e.preventDefault();
      submitForm();
    });

  $contactUsModal.on('hidden.bs.modal', function () {
    if (typeof formValidation.reset == 'function') {
      formValidation.reset();
      $('body').removeClass('_issue-report');
      clearFields();
    }
  });
});
