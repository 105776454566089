import {
  MODALS,
  MIDDLE_WIDTH,
  // SHOW_ADDS_FREE_MODAL_DELAY
} from '../config';
const $document = $(document);

$document.ready(function () {
  const $header = $('[data-selector="header"]');
  if (!$header.length) return;

  $('#show-tgc-paywall').on('click', () => {
    $(MODALS.TGC_PAYWALL_MODAL_NEW).modal('show');
  });

  const addsFreeHeaderBtn = '[data-selector="add-free-header-btn"]';
  const addsFreeModalBtn = '[data-selector="add-free-modal-btn"]';
  const headerAppDropdownToggleBtn = '[data-selector="app-dropdown-toggle"]';
  const headerAppDropdownArrow = '[data-selector="header-app-dropdown-arrow"]';
  const headerDropdownList = '[data-selector="header-dropdown-list"]';
  const mainMenuDropdown = '[data-selector="main-menu-dropdown"]';

  const appDropdown = '[data-selector="app-dropdown"]';
  const appDropdownMenu = '[data-selector="app-dropdown-menu"]';
  let addsFreeModalBtnClicked = false;

  $document
    .on('click', headerAppDropdownToggleBtn, (e) => {
      e.stopPropagation();
      e.preventDefault();

      if (window.innerWidth <= MIDDLE_WIDTH) {
        const $btn = $(e.currentTarget);
        $btn.find(headerAppDropdownArrow).toggleClass('_closed');

        // toggle slide apps menu
        const $appDropdownMenu = $btn.closest(appDropdown).find(appDropdownMenu);
        $appDropdownMenu.slideToggle();

        if (!$appDropdownMenu.length) {
          const $headerDropdownList = $btn.closest(mainMenuDropdown).find(headerDropdownList);
          $headerDropdownList.slideToggle();
        }
      }
    })
    .on('click', addsFreeHeaderBtn, () => {
      ga('send', 'event', 'Ad-Free Click', 'Ad-Free Click', 'Click');
      $(MODALS.WEBSITE_PLANS_MODAL).modal('show');
    })
    .on('click', addsFreeModalBtn, () => {
      addsFreeModalBtnClicked = true;
      ga('send', 'event', 'Adsence_PopUp', 'Ad-Free Click', 'Click');
      $(MODALS.ADDS_FREE).modal('hide');
    });

  $(MODALS.ADDS_FREE).on('hidden.bs.modal', () => {
    if (!addsFreeModalBtnClicked) return;
    $(MODALS.WEBSITE_PLANS_MODAL).modal('show');
  });

  // setTimeout(() => {
  //   const currentUrl = $('[data-url]').attr('data-url');
  //   if (currentUrl !== 'sitemap') return;
  //
  //   $(MODALS.ADDS_FREE).modal('show');
  //   ga('send', 'event', 'Adsence_PopUp', 'Show', 'Load');
  // }, SHOW_ADDS_FREE_MODAL_DELAY);
});
