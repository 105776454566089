$(document).ready(() => {
  const $mainBannerCarousel = $('[data-selector="main-banner-slider"]');

  if (!$mainBannerCarousel || !$mainBannerCarousel.length) return;

  const $mainBannerContent = $('[data-selector="main-banner-content"]');

  // callback for the first time Slick initializes
  $mainBannerCarousel.on('init', () => {
    $mainBannerContent.removeClass('_loading');
    $mainBannerCarousel.show();
  });

  $mainBannerCarousel.not('.slick-initialized').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    adaptiveHeight: true
  });
});
