import {scrollToHashedElement} from '../utils';

$(document).ready(function () {
  const mainBannerLinkSelector = '[data-selector="main-banner-link"][href="/#go-to-home-offer"]';

  const scrollToHomeOfferSection = (e) => {
    const hash = '#go-to-home-offer';

    if (e && e.currentTarget.hash === hash // click to banner
      || !e && location.hash === hash) {     // on page load

      // do no reload the page if current page is home page
      if (window.location.pathname === '' || window.location.pathname === '/') {
        e && e.preventDefault();
      }

      scrollToHashedElement('#homepage-promo', {scrollTimeout: 0});
    }
  };

  $(document)
    .on('click', mainBannerLinkSelector, function () {
      scrollToHashedElement('#homepage-promo', {scrollTimeout: 0});
    });

  setTimeout(scrollToHomeOfferSection, 500);
});
