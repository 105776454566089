import {isUiTestingMode} from '../utils';

(function () {
  if (isUiTestingMode()) return null;
  if ($(['data-selector="tg-online"']).length) return;

  const options = {
    innerHTML: '<div></div>',
    className: 'go-to-top-btn',
    offset: '20px',
  };

  options.offset = options.offset || '1rem';

  const backTop = document.createElement('a');
  backTop.href = '#top';
  backTop.innerHTML = options.innerHTML || 'Back to Top';
  backTop.id = options.id || '';
  backTop.className = options.className || '';

  backTop.style.position = 'fixed';
  backTop.style.right = options.offset;
  backTop.style.bottom = '-100px';
  backTop.style['transition-property'] = 'bottom';

  if (!options.transition) {
    options.transition = {};
  }

  backTop.style['transition-duration'] = '800ms';
  backTop.style['transition-timing-function'] = 'linear';

  window.addEventListener('scroll', function () {
    if (window.pageYOffset < 100) {
      backTop.style.bottom = '-100px';
    } else {
      backTop.style.bottom = options.offset;
    }
  });

  backTop.addEventListener('click', function(e) {
    const $body = $('body, html');
      $body.animate({
        scrollTop :0
      }, 500);

    // Remove hash from URL:
    history.pushState(
      '',
      document.title,
      window.location.pathname + window.location.search
    );
    e.preventDefault();
  });

  document.body.appendChild(backTop);
})();
