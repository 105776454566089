import {
  ENDPOINT,
  GET_PARAMS
} from '../config';
import Qs from 'qs';
import {deleteGetParamFromUrl} from '../utils';


$(document).ready(function () {
  const notification = '[data-selector="notification"]';
  const $notification = $(notification);
  if (!$notification.length) return;

  const headerUserDropDown = '[data-selector="header-user-dropdown"]';

  const notificationDropdownClose = '[data-selector="notification-close-btn"]';
  const notificationDropdownToggleBtn = '[data-selector="notification-toggle-btn"]';
  const $notificationDropdown = $('[data-selector="notification-dropdown"]');
  const notificationMarkAsRead = '[data-selector="notification-mark-all-as-read"]';
  const notificationMessageLink = '[data-selector="notification-message-link"]';
  const $notificationContainer = $('[data-selector="header-notification"]');
  const $notificationActiveIcon = $('[data-selector="notification-active-icon"]');
  const $notificationInActiveIcon = $('[data-selector="notification-inactive-icon"]');

  const accSettingsMessagesBtn = '[data-selector="account-settings-my-messages-btn"]';
  const $messagesContentList = $('[data-selector="messages-content-list"]');
  const messagePreviewText = '[data-selector="message-preview-text"]';
  const $messagePreviewList = $('[data-selector="message-preview-list"]');
  const messageIdDataAttr = 'data-message-id';
  const allNotReaderMessageIdsDataAttr = 'data-all-messages-ids';
  const markAllAsReadBtn = '[data-selector="messages-mark-all-as-read-btn"]';
  const messageBackBtn = '[data-selector="messages-back-btn"]';
  const $messageTopTitle = $('[data-selector="messages-top-title"]');

  const messageReadClassName = '_is-read';


  const showMessageById = (messageId) => {
    const currentMessageSelector = `[${messageIdDataAttr}="${messageId}"]`;
    const $messagePreviewItem = $messagePreviewList.find(currentMessageSelector);

    $messagePreviewItem.click();
  };

  const markMessageAsReadOnUI = (messageId) => {
    const currentMessageSelector = `[${messageIdDataAttr}="${messageId}"]`;
    const $messagePreviewItem = $messagePreviewList.find(currentMessageSelector);
    const $headerMessageItem = $notificationContainer.find(currentMessageSelector);

    $messagePreviewItem.addClass(messageReadClassName);
    $headerMessageItem.addClass(messageReadClassName);
  };

  const openMessageOnPageLoad = () => {
    const queryObject = Qs.parse(location.search.substr(1));
    const messageId = queryObject[GET_PARAMS.MESSAGE_ID];
    if (!messageId) return;

    messageId.replace('/','');
    const currentMessageSelector = `[${messageIdDataAttr}="${messageId}"]`;
    const $messagePreviewItem = $messagePreviewList.find(currentMessageSelector);
    $messagePreviewItem.click();
  };

  const sendMessagesReadRequest = (messageIdsArray=null) => {
    $.ajax({
      url: ENDPOINT.NOTIFICATION_MARK_AS_READ,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({ids: messageIdsArray})
    }).then((response) => {
      if (!response.has_unread_messages) {
        $(markAllAsReadBtn).hide();
        $(notificationMarkAsRead).hide();
        makeNotificationIconInactive();
      }
    });
  };

  const makeNotificationIconInactive = () => {
    $notificationActiveIcon.hide();
    $notificationInActiveIcon.show();
  };

  const updateUrlWithMessageIdGetParam = (messageId) => {
    if (window.history.replaceState) {
      // eslint-disable-next-line max-len
      const newUrl = `${location.origin}${location.pathname}?${GET_PARAMS.MESSAGE_ID}=${messageId}/#my-messages`;
      history.pushState({}, null, newUrl);
    }
  };


  $(document)
    // header "mark all as read" btn click
    .on('click', notificationMarkAsRead, () => {
      const notReadMessagesIdsArray = $notificationContainer
                                        .attr(allNotReaderMessageIdsDataAttr)
                                        .split(' ');
      notReadMessagesIdsArray.splice(-1,1); // remove extra/empty array item (last)
      notReadMessagesIdsArray.forEach((id) => {markMessageAsReadOnUI(id);});

      sendMessagesReadRequest(null);
      makeNotificationIconInactive();
    })
    // my account. message preview item click
    .on('click', messagePreviewText, (e) => {
      const $messageTextItem = $(e.currentTarget);
      const messageId = $messageTextItem.attr(messageIdDataAttr);
      const currentMessageSelector = `[${messageIdDataAttr}="${messageId}"]`;
      const $messageContentItem = $messagesContentList.find(currentMessageSelector);
      const $messageContentAllItems = $messagesContentList.find(`[${messageIdDataAttr}]`);

      $messagePreviewList.hide();
      $messageContentAllItems.hide();
      $messageContentItem.show();
      $(messageBackBtn).show();
      $messageTopTitle.hide();
      $(markAllAsReadBtn).hide();

      if (!$messageTextItem.hasClass(messageReadClassName)) {
        markMessageAsReadOnUI(messageId);
        sendMessagesReadRequest([messageId]);
      }

      updateUrlWithMessageIdGetParam(messageId);
    })
    // header. message item click
    .on('click', notificationMessageLink, (e) => {
      // prevent page reload, and just show message
      if ($(accSettingsMessagesBtn).length) {
        e.preventDefault();
        $(accSettingsMessagesBtn).click();
        const $messageLink = $(e.currentTarget);
        const messageId = $messageLink.attr(messageIdDataAttr);

        if (!$messageLink.hasClass(messageReadClassName)) {
          markMessageAsReadOnUI(messageId);
          sendMessagesReadRequest([messageId]);
        }

        updateUrlWithMessageIdGetParam(messageId);
        showMessageById(messageId);
      }

      $notificationDropdown.hide();
    })
    // header. dropdown hide btn
    .on('click', notificationDropdownClose, () => {
      $notificationDropdown.hide();
    })
    // header. dropdown show/hide btn click
    .on('click', notificationDropdownToggleBtn, () => {
      $notificationDropdown.toggle();
    })
    // click on any elem except notification dropdown to hide the dropdown
    .on('click', (e) => {
      e.stopPropagation();
      if (!$(e.target).closest(notification).length) $notificationDropdown.hide();
    })
    // acc settings. "mark all as read" btn click
    .on('click', markAllAsReadBtn, () => {
      const messagePreviewItems = $(messagePreviewText);
      const $notReadPreviewMessageItems = messagePreviewItems.not(`.${messageReadClassName}`);
      const notReadMessagesIdsArray = [];

      $notReadPreviewMessageItems.each((i, message) => {
        notReadMessagesIdsArray.push($(message).attr(messageIdDataAttr));
      });

      notReadMessagesIdsArray.forEach((id) => {markMessageAsReadOnUI(id);});

      sendMessagesReadRequest(null);
      makeNotificationIconInactive();
      $notificationDropdown.hide();
      $(markAllAsReadBtn).html('');
    })
    // acc settings. my-messages nav btn click
    .on('click', accSettingsMessagesBtn, () => {
      $(messageBackBtn).click();
      deleteGetParamFromUrl(GET_PARAMS.MESSAGE_ID);
      ga('send', 'event', 'Account settings', 'Message Page', 'Visit');
    })
    // acc settings. back to messages preview btn click
    .on('click', messageBackBtn, () => {
      const $messageContentAllItems = $messagesContentList.find(`[${messageIdDataAttr}]`);
      $messagePreviewList.show();
      $messageContentAllItems.hide();
      $(messageBackBtn).hide();
      $messageTopTitle.show();
      $(markAllAsReadBtn).show();
      deleteGetParamFromUrl(GET_PARAMS.MESSAGE_ID);
    })
    .on('click', headerUserDropDown, () => {
      $notificationDropdown.hide();
    });

  openMessageOnPageLoad();
});
